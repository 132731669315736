<template>
  <div class="container page__inner">
    <div class="page__content--wider">
      <div class="page__head2">
        <div
          class="page__head__container align-items-center justify-content-between"
        >
          <div class="page__head__text">
            <h1 class="page__head2__title">All Groups</h1>
          </div>

          <div class="page__head__actions">
            <div class="d-flex">
              <!--<div class="align-self-center mx-2 dropdown">
                  <button
                    class="button button--grey text--capital text--small d-flex align-items-center"
                    type="button"
                    id="dropdownMenuButton"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    custom columns
                    <span class="dropdown__icon">
                      <ion-icon name="caret-down-outline"></ion-icon>
                    </span>
                  </button>
                  <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                    <a
                      class="dropdown-item text--capital"
                      data-modal="#addNewCustomModal"
                      >new column preset</a
                    >
                    <a class="dropdown-item text--capital">custom customs</a>
                  </div>
                </div>-->

              <!--<button
                  type="button"
                  data-modal="#editColumnModal"
                  class="mx-2 button page__body__header__button button--grey text--capital"
                >
                  <span class="d-inline-flex mr-2">edit columns</span>
                </button>-->

              <div class="align-self-center mx-2 dropdown">
                <button
                  type="button"
                  class="w-100 button page__body__header__button button--grey text--capital"
                  @click="toggleFilterModal"
                >
                  <span class="d-inline-flex mr-2">filter</span>
                </button>
              </div>

              <div class="align-self-center mx-2 dropdown">
                <button
                  class="button button--grey text--capital text--small d-flex align-items-center"
                  type="button"
                  id="dropdownMenuButton"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  export
                  <span class="dropdown__icon">
                    <ion-icon name="caret-down-outline"></ion-icon>
                  </span>
                </button>
                <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                  <a
                    href="javascript:void(0)"
                    v-if="userCanExportToPDF"
                    @click="pdfDownload"
                    class="dropdown-item text--capital"
                    >PDF</a
                  >
                  <a
                    href="javascript:void(0)"
                    v-if="userCanExportToExcel"
                    @click="processDownload"
                    class="dropdown-item text--capital"
                    >excel</a
                  >
                  <!-- <download-excel
                    class="dropdown-item text--capital"
                    style="cursor: pointer"
                    :data="download"
                    :fields="json_fields"
                    name="groups.xls"
                  >
                    excel
                  </download-excel> -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- <div class="page__head_subtitle pt-4">
        <p class="page__head2__subtitle">
          Where <span class="text--black text--600">Loans Balance</span> is more
          than 0.00
          <a data-modal="#customGroupFilter" class="ml-3 d-inline-block">
            <img
              src="../../../../assets/img/edit-square.svg"
              alt=""
              srcset=""
            />
          </a>
        </p>
      </div> -->
      <div style="display: flex; width: 50%; font-size: 14px" v-if="filterText">
        <div style="width: 80%">
          {{ filterText }}
        </div>
        <div style="display: flex; width: 10%">
          <span style="cursor: pointer; font-size: 17px" @click="editFilter">
            <i style="padding-right: 5px" class="bi bi-pencil-square"></i>
          </span>
          <span style="cursor: pointer; font-size: 17px" @click="deleteFilter">
            <i style="padding-left: 5px" class="bi bi-x-circle-fill"></i>
          </span>
        </div>
      </div>
      <div class="page__body">
        <table class="customtable w-100">
          <thead>
            <tr>
              <th
                scope="col"
                class="table__header__text table__text text--capital"
              >
                Group Name
              </th>
              <th
                scope="col"
                class="table__header__text table__text text--capital"
              >
                ID
              </th>
              <th
                scope="col"
                class="table__header__text table__text text--capital"
              >
                Branch
              </th>
              <th
                scope="col"
                class="table__header__text table__text text--capital"
              >
                loan balance
              </th>
              <th
                scope="col"
                class="table__header__text table__text text--capital"
              >
                last modified
              </th>
              <!-- <th
                scope="col"
                class="table__header__text table__text text--capital"
              >
                Actions
              </th> -->
            </tr>
          </thead>
          <tbody v-if="isLoading">
            <tr>
              <td colspan="7">
                <div
                  class="d-flex align-items-center justify-content-center p5"
                >
                  <div
                    class="spinner-border"
                    style="width: 3rem; height: 3rem"
                    role="status"
                  >
                    <span class="sr-only">Loading...</span>
                  </div>
                  <span
                    class="text--black text--600 text--medium d-inline-flex ml-4"
                    >Please wait</span
                  >
                </div>
              </td>
            </tr>
          </tbody>
          <tbody v-else-if="allGroups.length">
            <tr v-for="item in allGroups" v-bind:key="item.groupKey">
              <td>
                <router-link
                  tag="a"
                  :to="{
                    name: 'group_overview',
                    params: { id: item.groupKey },
                  }"
                  class="table__body__text table__text text--capital text--primary"
                >
                  {{ item.groupName }}</router-link
                >
              </td>
              <td>
                <span class="table__body__text table__text text--capital">{{
                  item.uniqueGroupId
                }}</span>
              </td>
              <td>
                <span class="table__body__text table__text text--capital">{{
                  item.branch ? item.branch.branchName : item.branchName
                }}</span>
              </td>
              <td>
                <span class="table__body__text table__text text--capital"
                  >0.00</span
                >
              </td>
              <td>
                <span class="table__body__text table__text text--capital">{{
                  item.lastModifiedDate | formatDatePipe
                }}</span>
              </td>
              <!-- <td>
                <div class="align-self-center dropdown">
                  <button
                    class="
                      button button--grey
                      text--capital text--small
                      d-flex
                      align-items-center
                    "
                    type="button"
                    id="dropdownMenuButton"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    action
                    <span class="dropdown__icon">
                      <ion-icon name="caret-down-outline"></ion-icon>
                    </span>
                  </button>
                  <div
                    class="dropdown-menu"
                    aria-labelledby="dropdownMenuButton"
                  >
                    <a
                      class="dropdown-item text--capital"
                      data-toggle="modal"
                      data-target="#editGroupModal"
                      v-on:click="editGroup(item)"
                      v-if="userCanEditGroup"
                      >edit</a
                    >
                  </div>
                </div>
              </td> -->
            </tr>
          </tbody>
        </table>
        <div v-if="allGroups.length" class="pagination">
          <div class="d-flex align-items-center justify-content-between w-100">
            <div class="text--capital text--regular pagination__text">
              showing
              <span class="d-inline-block text--primary mr-3">
                <label for="">
                  <select
                    v-model.number="fetchModel.pageSize"
                    class="select select--lg select--page"
                    @change="changePageSize(fetchModel.pageSize)"
                  >
                    <option
                      v-for="(psize, id) in pagesizes"
                      :key="id"
                      :id="id"
                      :value="psize.size"
                    >
                      {{ psize.size }}
                    </option>
                  </select>
                </label>
              </span>

              <!--<span
                class="d-inline-block text--primary"
                v-if="paging.totalCount == 0"
                >0</span
              >-->
              <span class="d-inline-block text--primary">{{
                paging.pageIndex || paging.currentPage
              }}</span>
              to
              <span class="d-inline-block text--primary">{{
                paging.totalPages
              }}</span>
              <!--of
              <span>{{ paging.totalCount }}</span>-->
            </div>
            <div
              class="pagination__buttons d-flex align-items-center justify-content-between"
            >
              <button
                class="button button--sm text--regular"
                v-if="paging.hasPreviousPage"
                @click="stepPage('previous')"
              >
                <ion-icon name="chevron-back-outline"></ion-icon>
              </button>
              <!-- <button
                class="button text--regular button--sm ml-2 button--primary text--white"
              >
                1
              </button>
              <button class="button text--regular button--sm ml-2">2</button>
              <button class="button text--regular button--sm ml-2">3</button> -->
              <div
                v-if="!isLargeSizePaged"
                class="d-flex align-items-center pagination__buttons"
              >
                <button
                  class="button text--regular button--sm ml-2"
                  v-for="num in paging.totalPages"
                  :key="num"
                  :class="{
                    'button--primary text--white':
                      paging.pageIndex === num || paging.currentPage === num,
                  }"
                  :disabled="
                    paging.pageIndex === num || paging.currentPage === num
                  "
                  @click="changePage(num)"
                >
                  {{ num }}
                </button>
              </div>

              <div v-else class="text--capital text--regular pagination__text">
                Page
                <span class="d-inline-block text--primary">{{
                  paging.currentPage
                }}</span>
                of
                <span class="d-inline-block text--primary">{{
                  paging.totalPages
                }}</span>
                from
                <span class="d-inline-block text--primary">{{
                  paging.totalCount
                }}</span>
                Records
              </div>

              <button
                class="button text--regular button--sm ml-2"
                v-if="paging.hasNextPage"
                @click="stepPage('next')"
              >
                <ion-icon name="chevron-forward-outline"></ion-icon>
              </button>
            </div>
          </div>
        </div>
      </div>

      <div
        class="modal modal__right"
        id="editColumnModal"
        tabindex=""
        role="dialog"
        aria-labelledby="modalLabel"
        aria-hidden="true"
      >
        <div class="modal__dialog" role="document">
          <div class="modal__content">
            <div class="modal__head">
              <div class="modal__head__icon">
                <a
                  data-dismiss="#editColumnModal"
                  class="modal__close modal__close--black"
                >
                  <ion-icon name="arrow-back-outline"></ion-icon>
                </a>
              </div>
              <h5 class="modal__head__title text--capital">
                Edit Custom Views Fields
              </h5>
            </div>
            <div class="modal__body">
              <form>
                <div class="form__item">
                  <label class="form__label">Available Columns</label>
                  <select class="select select--lg" required>
                    <option value="" disabled selected>
                      Select available columns
                    </option>
                    <option value="">option 1</option>
                    <option value="">option 2</option>
                    <option value="">option 3</option>
                  </select>
                </div>

                <div class="form__item">
                  <label class="form__label">Selected Columns</label>
                  <div class="form__item_dottedbg px-5 py-3">
                    <draggable>
                      <transition-group class="move" tag="div">
                        <div
                          class="d-flex my-3 align-items-center justify-content-between item1"
                          key="item1"
                        >
                          <p
                            class="text--regular text--black form__item_text3 text--capital"
                          >
                            full name
                          </p>
                          <button
                            type="button"
                            class="mx-2 button button--sm button--cancel text--capital"
                          >
                            <span class="d-inline-flex mr-2">delete</span>
                          </button>
                        </div>
                        <div
                          class="d-flex my-3 align-items-center justify-content-between item2"
                          key="item2"
                        >
                          <p
                            class="text--regular text--black form__item_text3 text--capital"
                          >
                            ID
                          </p>
                          <button
                            type="button"
                            class="mx-2 button button--sm button--cancel text--capital"
                          >
                            <span class="d-inline-flex mr-2">delete</span>
                          </button>
                        </div>
                        <div
                          class="d-flex my-3 align-items-center justify-content-between item3"
                          key="item3"
                        >
                          <p
                            class="text--regular text--black form__item_text3 text--capital"
                          >
                            customer state
                          </p>
                          <button
                            type="button"
                            class="mx-2 button button--sm button--cancel text--capital"
                          >
                            <span class="d-inline-flex mr-2">delete</span>
                          </button>
                        </div>
                        <div
                          class="d-flex my-3 align-items-center justify-content-between item4"
                          key="item4"
                        >
                          <p
                            class="text--regular text--black form__item_text3 text--capital"
                          >
                            account officer
                          </p>
                          <button
                            type="button"
                            class="mx-2 button button--sm button--cancel text--capital"
                          >
                            <span class="d-inline-flex mr-2">delete</span>
                          </button>
                        </div>
                        <div
                          class="d-flex my-3 align-items-center justify-content-between item5"
                          key="item5"
                        >
                          <p
                            class="text--regular text--black form__item_text3 text--capital"
                          >
                            loan deposit
                          </p>
                          <button
                            type="button"
                            class="mx-2 button button--sm button--cancel text--capital"
                          >
                            <span class="d-inline-flex mr-2">delete</span>
                          </button>
                        </div>
                        <div
                          class="d-flex my-3 align-items-center justify-content-between item6"
                          key="item6"
                        >
                          <p
                            class="text--regular text--black form__item_text3 text--capital"
                          >
                            deposit balance
                          </p>
                          <button
                            type="button"
                            class="mx-2 button button--sm button--cancel text--capital"
                          >
                            <span class="d-inline-flex mr-2">delete</span>
                          </button>
                        </div>
                        <div
                          class="d-flex my-3 align-items-center justify-content-between item7"
                          key="item7"
                        >
                          <p
                            class="text--regular text--black form__item_text3 text--capital"
                          >
                            last modified
                          </p>
                          <button
                            type="button"
                            class="mx-2 button button--sm button--cancel text--capital"
                          >
                            <span class="d-inline-flex mr-2">delete</span>
                          </button>
                        </div>
                      </transition-group>
                    </draggable>
                  </div>
                </div>
                <div class="row align-items-end">
                  <div class="col-6">
                    <div class="form__item">
                      <label class="form__label">sort by</label>
                      <input
                        type="text"
                        class="input form__input form__input--lg"
                        placeholder="Enter Title"
                      />
                    </div>
                    <div class="form__item">
                      <label
                        for="includeTotal"
                        class="d-block has-checkbox page__body__header__label text--grey text--capital mb-3 mr-3"
                        >Include Totals
                        <input
                          type="checkbox"
                          id="includeTotal"
                          class="form-"
                        />
                        <span class="checkmark"></span>
                      </label>
                    </div>
                  </div>
                  <div class="col-6">
                    <div class="form__item">
                      <select class="select select--lg" required>
                        <option value="" disabled selected>
                          ascending order
                        </option>
                        <option value="">option 1</option>
                        <option value="">option 2</option>
                        <option value="">option 3</option>
                      </select>
                    </div>
                    <div class="form__item">
                      <label
                        for="includeTimestamp"
                        class="d-block has-checkbox page__body__header__label text--grey text--capital mb-3 mr-3"
                        >Include Timestamp
                        <input
                          type="checkbox"
                          id="includeTimestamp"
                          class="form-"
                        />
                        <span class="checkmark"></span>
                      </label>
                    </div>
                  </div>
                </div>
                <div class="form__item">
                  <div class="form__action">
                    <button
                      data-dismiss="#editColumnModal"
                      class="button form__button form__button--lg w-30 save-changes"
                      type="button"
                    >
                      apply changes
                    </button>
                    <a
                      role="button"
                      data-dismiss="#editColumnModal"
                      class="modal__close form__action__text form__action__text--danger text--capital ml6"
                    >
                      cancel
                    </a>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      <div
        class="modal modal__right"
        id="addNewCustomModal"
        tabindex=""
        role="dialog"
        aria-labelledby="modalLabel"
        aria-hidden="true"
      >
        <div class="modal__dialog" role="document">
          <div class="modal__content">
            <div class="modal__head">
              <div class="modal__head__icon">
                <a
                  data-dismiss="#addNewCustomModal"
                  class="modal__close modal__close--black"
                >
                  <ion-icon name="arrow-back-outline"></ion-icon>
                </a>
              </div>
              <h5 class="modal__head__title text--capital">
                Creating a new customer preset
              </h5>
            </div>
            <div class="modal__body">
              <form>
                <div class="form__item">
                  <label class="form__label">Preset Name</label>
                  <input
                    type="text"
                    class="input form__input form__input--lg"
                    placeholder="Enter Preset Name"
                  />
                </div>
                <div class="d-flex">
                  <div class="form__item mr-3">
                    <label
                      for="sharedPreset"
                      class="d-block has-checkbox page__body__header__label text--grey text--capital mb-3 mr-3"
                      >Shared preset
                      <input type="checkbox" id="sharedPreset" class="form-" />
                      <span class="checkmark"></span>
                    </label>
                  </div>
                  <div class="form__item">
                    <label
                      for="defaultPreset"
                      class="d-block has-checkbox page__body__header__label text--grey text--capital mb-3 mr-3"
                      >Defualt Preset
                      <input type="checkbox" id="defaultPreset" class="form-" />
                      <span class="checkmark"></span>
                    </label>
                  </div>
                </div>
                <div class="form__item">
                  <label class="form__label">Available Columns</label>
                  <select class="select select--lg" required>
                    <option value="" disabled selected>
                      Select available columns
                    </option>
                    <option value="">option 1</option>
                    <option value="">option 2</option>
                    <option value="">option 3</option>
                  </select>
                </div>

                <div class="form__item">
                  <label class="form__label">Selected Columns</label>
                  <div class="form__item_dottedbg px-5 py-3">
                    <draggable class="move">
                      <transition-group tag="div">
                        <div
                          class="d-flex my-3 align-items-center justify-content-between item1"
                          key="item1"
                        >
                          <p
                            class="text--regular text--black form__item_text3 text--capital"
                            :key="1"
                          >
                            loan deposit
                          </p>
                          <button
                            type="button"
                            class="mx-2 button button--sm button--cancel text--capital"
                          >
                            <span class="d-inline-flex mr-2">delete</span>
                          </button>
                        </div>
                        <div
                          class="d-flex my-3 align-items-center justify-content-between item2"
                          key="item2"
                        >
                          <p
                            class="text--regular text--black form__item_text3 text--capital"
                          >
                            deposit balance
                          </p>
                          <button
                            type="button"
                            class="mx-2 button button--sm button--cancel text--capital"
                          >
                            <span class="d-inline-flex mr-2">delete</span>
                          </button>
                        </div>
                        <div
                          class="d-flex my-3 align-items-center justify-content-between item3"
                          key="item3"
                        >
                          <p
                            class="text--regular text--black form__item_text3 text--capital"
                          >
                            last modified
                          </p>
                          <button
                            type="button"
                            class="mx-2 button button--sm button--cancel text--capital"
                          >
                            <span class="d-inline-flex mr-2">delete</span>
                          </button>
                        </div>
                        <div
                          class="d-flex my-3 align-items-center justify-content-between item4"
                          key="item4"
                        >
                          <p
                            class="text--regular text--black form__item_text3 text--capital"
                          >
                            account officer
                          </p>
                          <button
                            type="button"
                            class="mx-2 button button--sm button--cancel text--capital"
                          >
                            <span class="d-inline-flex mr-2">delete</span>
                          </button>
                        </div>
                      </transition-group>
                    </draggable>
                  </div>
                </div>
                <div class="row align-items-end">
                  <div class="col-6">
                    <div class="form__item">
                      <label class="form__label">sort by</label>
                      <input
                        type="text"
                        class="input form__input form__input--lg"
                        placeholder="Enter Title"
                      />
                    </div>
                    <div class="form__item">
                      <label
                        for="includeTotal2"
                        class="d-block has-checkbox page__body__header__label text--grey text--capital mb-3 mr-3"
                        >Include Totals
                        <input
                          type="checkbox"
                          id="includeTotal2"
                          class="form-"
                        />
                        <span class="checkmark"></span>
                      </label>
                    </div>
                  </div>
                  <div class="col-6">
                    <div class="form__item">
                      <select class="select select--lg" required>
                        <option value="" disabled selected>
                          ascending order
                        </option>
                        <option value="">option 1</option>
                        <option value="">option 2</option>
                        <option value="">option 3</option>
                      </select>
                    </div>
                    <div class="form__item">
                      <label
                        for="includeTimestamp2"
                        class="d-block has-checkbox page__body__header__label text--grey text--capital mb-3 mr-3"
                        >Include Timestamp
                        <input
                          type="checkbox"
                          id="includeTimestamp2"
                          class="form-"
                        />
                        <span class="checkmark"></span>
                      </label>
                    </div>
                  </div>
                </div>
                <div class="form__item">
                  <div class="form__action">
                    <button
                      data-dismiss="#addNewCustomModal"
                      class="button form__button form__button--lg w-30 save-changes"
                      type="button"
                    >
                      apply changes
                    </button>
                    <a
                      role="button"
                      data-dismiss="#addNewCustomModal"
                      class="modal__close form__action__text form__action__text--danger text--capital ml6"
                    >
                      cancel
                    </a>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <div
        class="modal modal__right"
        id="customGroupFilter"
        tabindex=""
        role="dialog"
        aria-labelledby="modalLabel"
        aria-hidden="true"
      >
        <div class="modal__dialog" role="document">
          <div class="modal__content">
            <div class="modal__head">
              <div class="modal__head__icon">
                <a
                  data-dismiss="#customGroupFilter"
                  class="modal__close modal__close--black"
                >
                  <ion-icon name="arrow-back-outline"></ion-icon>
                </a>
              </div>
              <h5 class="modal__head__title text--capital">
                Custom Groups Filter
              </h5>
            </div>
            <div class="modal__body">
              <form>
                <div class="form__item">
                  <div class="p-5 border-grey border-radius">
                    <div class="row">
                      <div class="col-6">
                        <div class="form__item">
                          <label class="form__label">Where</label>
                          <select class="select select--lg" required>
                            <option value="" disabled>Select Where</option>
                            <option value="" selected>Loan Balance</option>
                            <option value="">Account Balance</option>
                          </select>
                        </div>
                      </div>
                      <div class="col-6">
                        <div class="form__item">
                          <label class="form__label">More than</label>
                          <select class="select select--lg" required>
                            <option value="" disabled selected>
                              Select Value
                            </option>
                            <option value="">Option 1</option>
                            <option value="">Option 2</option>
                          </select>
                        </div>
                      </div>
                    </div>
                    <div class="row align-items-end justify-content-between">
                      <div class="col-6">
                        <div class="form__item mb-0">
                          <label class="form__label">Enter Amount</label>
                          <input
                            type="text"
                            class="input form__input form__input--lg"
                            placeholder="Enter Amount"
                          />
                        </div>
                      </div>
                      <div class="px-4">
                        <button
                          type="button"
                          class="button button--cancel text--capital"
                        >
                          <span class="d-inline-flex">delete</span>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="form__item">
                  <div class="p-5 border-grey border-radius">
                    <div class="row">
                      <div class="col-6">
                        <div class="form__item">
                          <label class="form__label">And </label>
                          <select class="select select--lg" required>
                            <option value="" disabled>Select Where</option>
                            <option value="" selected>Loan Balance</option>
                            <option value="">Account Balance</option>
                          </select>
                        </div>
                      </div>
                      <div class="col-6">
                        <div class="form__item">
                          <label class="form__label">Equals</label>
                          <select class="select select--lg" required>
                            <option value="" disabled selected>
                              Select Value
                            </option>
                            <option value="">Option 1</option>
                            <option value="">Option 2</option>
                          </select>
                        </div>
                      </div>
                    </div>
                    <div class="row align-items-end justify-content-between">
                      <div class="col-6">
                        <div class="form__item mb-0">
                          <label class="form__label">Enter Amount</label>
                          <input
                            type="text"
                            class="input form__input form__input--lg"
                            placeholder="Enter Amount"
                          />
                        </div>
                      </div>
                      <div class="px-4">
                        <button
                          type="button"
                          class="button button--cancel text--capital"
                        >
                          <span class="d-inline-flex">delete</span>
                        </button>
                      </div>
                    </div>
                  </div>
                  <div class="text-right row mt-4 mb5">
                    <div class="col ml-auto">
                      <div>
                        <select class="select select--lg w-auto" required>
                          <option value="" disabled>Select Filter</option>
                          <option value="" selected>Match All</option>
                          <option value="">Match None</option>
                        </select>
                        <button
                          type="button"
                          class="ml-5 button button--grey text--black text--capital"
                        >
                          <span class="d-inline-flex">Add Filter</span>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="form__item">
                  <div class="form__action">
                    <button
                      data-dismiss="#customGroupFilter"
                      class="button form__button form__button--lg w-30 save-changes"
                      type="button"
                    >
                      apply
                    </button>
                    <a
                      role="button"
                      data-dismiss="#customGroupFilter"
                      class="modal__close form__action__text form__action__text--danger text--capital ml6"
                    >
                      cancel
                    </a>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      <div
        class="modal modal__right"
        id="editGroupModal"
        tabindex=""
        role="dialog"
        aria-labelledby="modalLabel"
        aria-hidden="true"
      >
        <div class="modal__dialog" role="document">
          <div class="modal__content">
            <div class="modal__head">
              <div class="modal__head__icon">
                <a
                  data-dismiss="modal"
                  id="editCloseButton"
                  @click="clearForm"
                  class="modal__close modal__close--black"
                >
                  <ion-icon name="arrow-back-outline"></ion-icon>
                </a>
              </div>
              <h5 class="modal__head__title text--capital">Editing a Group</h5>
            </div>
            <div class="modal__body">
              <form @submit="saveEditForm">
                <div class="row">
                  <div class="col-6">
                    <div class="form__item">
                      <label class="form__label">Group Name</label>
                      <input
                        type="text"
                        class="input form__input form__input--lg"
                        placeholder="Enter Group Name"
                        v-model="editForm.groupName"
                        v-bind:class="{
                          'input-required': groupFormErr.groupName,
                        }"
                      />
                    </div>
                  </div>
                  <div class="col-6">
                    <div class="form__item">
                      <label class="form__label">Group Type</label>
                      <input
                        type="text"
                        class="input form__input form__input--lg"
                        placeholder="Enter Group Type"
                        readonly
                        value="Group"
                      />
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-6">
                    <div class="form__item">
                      <label class="form__label">Group ID</label>
                      <input
                        type="text"
                        class="input form__input form__input--lg"
                        placeholder="Enter Group ID"
                        v-model="editForm.uniqueGroupId"
                        v-bind:class="{
                          'input-required': groupFormErr.uniqueGroupId,
                        }"
                      />
                    </div>
                  </div>
                  <div class="col-6">
                    <div class="form__item">
                      <label class="form__label">Group Email</label>
                      <input
                        type="email"
                        class="input form__input form__input--lg"
                        placeholder="Enter Group email"
                        v-model="editForm.groupEmailAddress"
                        v-bind:class="{
                          'input-required': groupFormErr.groupEmailAddress,
                        }"
                      />
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-6">
                    <div class="form__item">
                      <label class="form__label">Group phone number</label>
                      <input
                        type="text"
                        class="input form__input form__input--lg"
                        placeholder="Enter Group phone number"
                        v-model="editForm.groupPhoneNumber"
                      />
                    </div>
                  </div>
                  <div class="col-6">
                    <div class="form__item">
                      <label class="form__label">Group Mobile number</label>
                      <input
                        type="text"
                        class="input form__input form__input--lg"
                        placeholder="Enter Group mobile number"
                        v-model="editForm.groupMobileNumber"
                      />
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-6">
                    <div class="form__item">
                      <label class="form__label">Group Customer Type</label>
                      <select
                        v-model="editForm.clientRoleKey"
                        class="select select--lg"
                        v-bind:class="{
                          'input-required': groupFormErr.clientRoleKey,
                        }"
                      >
                        <option
                          v-for="item in groupClientTypes"
                          v-bind:key="item.customerTypeKey"
                          :value="item.customerTypeKey"
                        >
                          {{ item.clientTypeName }}
                        </option>
                      </select>
                    </div>
                  </div>
                  <!-- <div class="col-6">
                  <div class="form__item">
                    <label class="form__label">Group loan cycle</label>
                    <input
                      type="text"
                      class="input form__input form__input--lg"
                      placeholder="Enter Group loan cycle"
                      v-model="editForm.loanCycle"
                    />
                  </div>
                </div> -->
                </div>

                <ul id="accordion" class="mb5">
                  <div v-if="group != null" class="modal__accordion">
                    <li
                      data-toggle="collapse"
                      class="sidebar-right__item modal__accordion--toggle"
                      data-target="#cggroupMembers"
                      aria-expanded="false"
                      aria-controls="cggroupMembers"
                    >
                      <h5
                        class="w-100 mb-0 d-flex align-items-center justify-content-between"
                      >
                        Group Members
                        <ion-icon name="chevron-down-outline"></ion-icon>
                      </h5>
                    </li>
                    <div
                      id="cggroupMembers"
                      class="collapse"
                      data-parent="#accordion"
                    >
                      <div class="pt-5">
                        <div
                          class="row"
                          v-for="item in group.memberKeyArray"
                          v-bind:key="item"
                        >
                          <div class="form__item col-8">
                            <input
                              type="text"
                              :value="group.memberNames[item]"
                              class="input form__input form__input--lg"
                              disabled
                            />
                          </div>
                          <div class="form__item col-3">
                            <button
                              v-on:click="deleteMemberFromGroup(item)"
                              type="button"
                              class="button page__body__header__button button--grey text--capital"
                            >
                              <span class="d-inline-flex mr-2">delete</span>
                              <img
                                src="../../../../assets/img/delete.svg"
                                alt=""
                                srcset=""
                              />
                            </button>
                          </div>
                        </div>
                        <div class="form__item">
                          <label class="form__label">Select Group Member</label>
                          <select
                            class="select select--lg"
                            v-model="selectedCustomerKey"
                          >
                            <option
                              v-for="item in allCustomers"
                              v-bind:key="item.customerKey"
                              :value="item.customerKey"
                            >
                              {{ item.customerFirstName }}
                              {{ item.customerLastName }}
                            </option>
                          </select>
                        </div>
                        <div class="form__item" id="branch-area"></div>

                        <div class="text-right mt-3">
                          <button
                            @click="addMemberToGroup(selectedCustomerKey)"
                            type="button"
                            class="d-flex align-items-center ml-auto button button--grey text--600 text--black text--small text--capital"
                          >
                            Add customer to group
                            <ion-icon
                              name="add-outline"
                              class="text--600 text--medium ml-2"
                            ></ion-icon>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div v-if="group != null" class="modal__accordion">
                    <li
                      data-toggle="collapse"
                      class="sidebar-right__item modal__accordion--toggle"
                      data-target="#cggroupRoles"
                      aria-expanded="false"
                      aria-controls="cggroupRoles"
                    >
                      <h5
                        class="w-100 mb-0 d-flex align-items-center justify-content-between"
                      >
                        Group Roles
                        <ion-icon name="chevron-down-outline"></ion-icon>
                      </h5>
                    </li>
                    <div
                      id="cggroupRoles"
                      class="collapse"
                      data-parent="#accordion"
                    >
                      <div class="pt-5">
                        <div
                          v-for="item in group.memberRoles"
                          v-bind:key="
                            'g' + item.groupRoleNameKey + 'c' + item.clientKey
                          "
                        >
                          <div
                            class="row align-items-center"
                            v-if="
                              item.groupRoleNameKey != null &&
                              item.clientKey != null
                            "
                          >
                            <div class="col-5">
                              <div class="form__item">
                                <label class="form__label">Group Role</label>
                                <input
                                  type="text"
                                  class="input form__input form__input--lg"
                                  :disabled="true"
                                  :value="item.groupRoleName"
                                />
                              </div>
                            </div>
                            <div class="col-5">
                              <div class="form__item">
                                <label class="form__label">Members</label>
                                <input
                                  type="text"
                                  class="input form__input form__input--lg"
                                  :disabled="true"
                                  :value="item.customerName"
                                />
                              </div>
                            </div>
                            <div class="col-2">
                              <button
                                @click="
                                  removeGroupRole(
                                    item.clientKey,
                                    item.groupRoleNameKey
                                  )
                                "
                                type="button"
                                class="button button--cancel text--500 text--small text--capital"
                              >
                                delete
                              </button>
                            </div>
                          </div>
                        </div>

                        <div class="row align-items-center">
                          <div class="col-5">
                            <div class="form__item">
                              <label class="form__label">Group Roles</label>
                              <select
                                v-model="groupRoleAddModel.groupRoleNameKey"
                                class="select select--lg"
                              >
                                <option
                                  v-for="item in allGroupRoleNames"
                                  v-bind:key="item.groupRoleNameKey"
                                  :value="item.groupRoleNameKey"
                                >
                                  {{ item.groupRoleName }}
                                </option>
                              </select>
                            </div>
                          </div>
                          <div class="col-5">
                            <div class="form__item">
                              <label class="form__label">Members</label>
                              <select
                                v-model="groupRoleAddModel.clientKey"
                                class="select select--lg"
                              >
                                <option
                                  v-for="item in group.memberKeyArray"
                                  v-bind:key="item"
                                  :value="item"
                                >
                                  {{ group.memberNames[item] }}
                                </option>
                              </select>
                            </div>
                          </div>
                        </div>

                        <div class="text-right mt-3">
                          <button
                            @click="addGroupRole()"
                            type="button"
                            class="d-flex align-items-center ml-auto button button--grey text--600 text--black text--small text--capital"
                            :disabled="
                              groupRoleAddModel.groupRoleNameKey == null &&
                              groupRoleAddModel.clientKey == null
                            "
                          >
                            Add Group Role
                            <ion-icon
                              name="add-outline"
                              class="text--600 text--medium ml-2"
                            ></ion-icon>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>

                  <!-- <div class="modal__accordion">
                  <li
                    data-toggle="collapse"
                    class="sidebar-right__item modal__accordion--toggle"
                    data-target="#cgaddress"
                    aria-expanded="false"
                    aria-controls="cgaddress"
                  >
                    <h5
                      class="w-100 mb-0 d-flex align-items-center justify-content-between"
                    >
                      Address
                      <ion-icon name="chevron-down-outline"></ion-icon>
                    </h5>
                  </li>
                  <div id="cgaddress" class="collapse" data-parent="#accordion">
                    <div class="pt-5">
                      <div class="row">
                        <div class="col-12">
                          <div class="form__item">
                            <label class="form__label"
                              >Street Address - Line 1</label
                            >
                            <input
                              type="text"
                              class="input input--right form__input form__input--lg"
                            />
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-12">
                          <div class="form__item">
                            <label class="form__label"
                              >Street Address - Line 2</label
                            >
                            <input
                              type="text"
                              class="input input--right form__input form__input--lg"
                            />
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-6">
                          <div class="form__item">
                            <label class="form__label">City</label>
                            <input
                              type="text"
                              class="input input--right form__input form__input--lg"
                              placeholder="Enter City"
                            />
                          </div>
                        </div>
                        <div class="col-6">
                          <div class="form__item">
                            <label class="form__label"
                              >State/Province/Region</label
                            >
                            <input
                              type="text"
                              class="input input--right form__input form__input--lg"
                              placeholder="Enter province"
                            />
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-6">
                          <div class="form__item">
                            <label class="form__label">Zip postal code</label>
                            <input
                              type="text"
                              class="input input--right form__input form__input--lg"
                              placeholder="Enter Zip postal code"
                            />
                          </div>
                        </div>
                        <div class="col-6">
                          <div class="form__item">
                            <label class="form__label">Country</label>
                            <input
                              type="text"
                              class="input input--right form__input form__input--lg"
                              placeholder="Enter country"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div> -->

                  <div class="modal__accordion">
                    <li
                      data-toggle="collapse"
                      class="sidebar-right__item modal__accordion--toggle"
                      data-target="#cgassociated"
                      aria-expanded="false"
                      aria-controls="cgassociated"
                    >
                      <h5
                        class="w-100 mb-0 d-flex align-items-center justify-content-between"
                      >
                        Associated
                        <ion-icon name="chevron-down-outline"></ion-icon>
                      </h5>
                    </li>
                    <div
                      id="cgassociated"
                      class="collapse"
                      data-parent="#accordion"
                    >
                      <div class="pt-5">
                        <div class="row">
                          <div class="col-6">
                            <div class="form__item">
                              <label class="form__label">Branch</label>
                              <select
                                class="select--lg w-100"
                                v-model="editForm.assignedBranchKey"
                                v-bind:class="{
                                  'input-required':
                                    groupFormErr.assignedBranchKey,
                                }"
                              >
                                <option disabled>Select branch</option>
                                <option
                                  v-for="item in allBranches"
                                  v-bind:key="item.branchKey"
                                  v-bind:value="item.branchKey"
                                >
                                  {{ item.branchName }} ({{ item.branchId }})
                                </option>
                              </select>
                            </div>
                          </div>
                          <div class="col-6">
                            <div class="form__item">
                              <label class="form__label">Center</label>
                              <select
                                class="select--lg w-100"
                                v-model="editForm.assignedCentreKey"
                                v-bind:class="{
                                  'input-required':
                                    groupFormErr.assignedCentreKey,
                                }"
                              >
                                <option disabled>Select centre</option>
                                <option
                                  v-for="item in allCentres"
                                  v-bind:key="item.centreKey"
                                  v-bind:value="item.centreKey"
                                >
                                  {{ item.centreName }} ({{ item.centreId }})
                                </option>
                              </select>
                            </div>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-6">
                            <div class="form__item">
                              <label class="form__label">Account Officer</label>
                              <select
                                class="select--lg w-100"
                                v-model="editForm.assignedUserKey"
                                v-bind:class="{
                                  'input-required':
                                    groupFormErr.assignedUserKey,
                                }"
                              >
                                <option disabled>Select Account Officer</option>
                                <option
                                  value="8eec0ada011f4f60a161312c3ae4db95"
                                >
                                  Test User
                                </option>
                              </select>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    class="modal__accordion"
                    v-for="(set, index) in customFields"
                    :key="set.customFieldSetKey"
                  >
                    <li
                      data-toggle="collapse"
                      class="sidebar-right__item modal__accordion--toggle"
                      :data-target="`#ccSet${index}`"
                      aria-expanded="false"
                      aria-controls="ccprofileNote"
                    >
                      <h5
                        class="w-100 mb-0 d-flex align-items-center justify-content-between"
                      >
                        {{ set.customFieldSetName }}
                        <ion-icon name="chevron-down-outline"></ion-icon>
                      </h5>
                    </li>
                    <div
                      :id="`ccSet${index}`"
                      class="collapse"
                      data-parent="#accordion"
                    >
                      <div class="pt-5">
                        <div class="row">
                          <div
                            class="col-6"
                            v-for="form in set.customFields"
                            :key="form.customFieldKey"
                          >
                            <div class="form__item">
                              <label class="form__label">{{
                                form.customFieldName
                              }}</label>
                              <input
                                :type="
                                  form.dataType === 'Number'
                                    ? 'number'
                                    : form.dataType === 'Date'
                                    ? 'date'
                                    : form.dataType === 'Checkbox'
                                    ? 'checkbox'
                                    : 'text'
                                "
                                v-if="form.dataType !== 'Selection'"
                                :id="form.customFieldKey"
                                class="input input--right form__input form__input--lg"
                                :placeholder="`Enter ${form.customFieldName}`"
                              />
                              <select
                                class="select--lg w-100"
                                v-if="form.dataType === 'Selection'"
                                :id="form.customFieldKey"
                              >
                                <option disabled selected value="null">
                                  Select {{ form.customFieldName }}
                                </option>
                                <option
                                  v-for="opt in form.customFieldSelections"
                                  :key="opt.customFieldSelectionKey"
                                  :value="opt.value"
                                >
                                  {{ opt.value }}
                                </option>
                              </select>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="modal__accordion">
                    <li
                      data-toggle="collapse"
                      class="sidebar-right__item modal__accordion--toggle"
                      data-target="#cgprofileNote"
                      aria-expanded="false"
                      aria-controls="cgprofileNote"
                    >
                      <h5
                        class="w-100 mb-0 d-flex align-items-center justify-content-between"
                      >
                        Profile Note
                        <ion-icon name="chevron-down-outline"></ion-icon>
                      </h5>
                    </li>
                    <div
                      id="cgprofileNote"
                      class="collapse"
                      data-parent="#accordion"
                    >
                      <div class="pt-5">
                        <div class="form__item">
                          <textarea
                            cols="5"
                            rows="5"
                            class="input form__input"
                            placeholder="Profile Note"
                            v-model="editForm.groupNotes"
                          ></textarea>
                        </div>
                      </div>
                    </div>
                  </div>
                </ul>
                <div class="form__item">
                  <div class="form__action">
                    <button
                      type="submit"
                      class="button form__button form__button--lg w-30 save-changes"
                      v-if="!isSaving"
                    >
                      Save group
                    </button>
                    <a
                      role="button"
                      class="button form__button form__button--lg w-25 modal__confirm__button modal__confirm__button--delete"
                      v-if="isSaving"
                      :disabled="true"
                    >
                      <div
                        class="spinner-border text-light"
                        role="status"
                      ></div>
                    </a>
                    <a
                      data-dismiss="modal"
                      v-if="!isSaving"
                      class="modal__close form__action__text form__action__text--danger text--capital ml6"
                      @click="clearForm"
                    >
                      cancel
                    </a>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      <div
        class="modal modal__confirm"
        id="deleteModal"
        tabindex=""
        role="dialog"
        aria-labelledby="modalLabel"
        aria-hidden="true"
      >
        <div class="w-100 h-100 d-flex">
          <div class="modal__dialog" role="document">
            <div class="modal__header mb-3">
              <div class="d-flex align-items-center justify-content-between">
                <span class="modal__header__title text--capital">
                  confirmation
                </span>
                <a
                  data-dismiss="#deleteModal"
                  class="modal__close modal__close--white d-flex align-items-center justify-content-between"
                >
                  <span class="text--capital text--regular"> close </span>
                  <ion-icon name="close-outline"></ion-icon>
                </a>
              </div>
              <span class="modal__header__subtitle text--capital">
                For inactive view
              </span>
            </div>
            <div class="modal__content">
              <div class="modal__body">
                <h5 class="modal__head__title text--capital mb-3">
                  Are you sure you want to delete this View inactive
                </h5>
                <p class="form__label">
                  Please confirm that you really want to delete the view
                  inactive
                </p>
                <div class="d-flex mt-5">
                  <button
                    data-dismiss="#deleteModal"
                    class="button button--lg modal__confirm__button modal__confirm__button--cancel mr-2"
                  >
                    cancel
                  </button>
                  <button
                    data-dismiss="#deleteModal"
                    class="button button--lg modal__confirm__button modal__confirm__button--delete ml-2 save-changes"
                  >
                    delete
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!--ALERT NOTIFICATION-->
      <div class="toaster show">
        <div
          class="toaster__container"
          v-if="alert.show"
          v-bind:class="{
            'toaster--success': alert.status === 'success',
            'toaster--error': alert.status === 'fail',
            'toaster--warn': alert.status === 'warn',
          }"
        >
          <div class="text-right">
            <span class="fa fa-times" @click="alert.show = false"></span>
          </div>
          <div>
            <span class="d-block text--small text--600 text--white">{{
              alert.title
            }}</span>
            <span class="d-block text--tiny text--400 text--white">{{
              alert.description
            }}</span>
          </div>
        </div>
      </div>
    </div>

    <CreateFilter
      :show="showFilterModal"
      @close="toggleFilterModal"
      @send-activate-filter="sendFilter"
      @reload="reloadFilter"
      @alertError="alertError"
      :filterEditValue="filterEdit"
      :showEditedValue="showEditedValue"
      :filterHeaderName="'Group'"
      filterCategory="group"
    />
  </div>
</template>

<script>
import draggable from "vuedraggable";
import Vue from "vue";
// import JsonExcel from "vue-json-excel";
import jsPDF from "jspdf";
import "jspdf-autotable";
import * as XLSX from "xlsx";
import moment from "moment";
import branchesService from "@/core/services/organization-service/branches.service";
import centresService from "@/core/services/organization-service/centres.service";
import customerService from "@/core/services/general-setup-service/customer.service";
import customerTypeService from "@/core/services/general-setup-service/customer-type.service";
import groupService from "@/core/services/general-setup-service/group.service";
import ApiService from "@/core/services/general.service";
import fieldSetService from "@/core/services/fieldset-service/fieldset.service";
import { Role } from "@/core/services/role.js";
import { mapState } from "vuex";

// import { delete } from "vue/types/umd";
export default Vue.extend({
  name: "groups",
  data: function () {
    return {
      content_header: {
        title: "Groups",
        subTitle:
          "Set up  Core Lending  Management with all the details for your organization. Define your institution details and contact information.",
      },
      showFilterModal: false,
      group: null,
      groupToDelete: null,
      isDeleting: false,
      isSaving: false,
      fetchModel: {
        pageIndex: 1,
        pageSize: 10,
        filters: [],
        filterCategory: "",
      },
      pagesizes: [
        {
          size: 10,
        },
        {
          size: 20,
        },
        {
          size: 50,
        },
      ],
      pageSize: 10,
      paging: {
        pageIndex: 0,
        totalPages: 0,
        pageSize: 0,
        totalCount: 0,
        hasPreviousPage: false,
        hasNextPage: false,
      },
      alert: {
        show: false,
        status: "Success",
        title: "Success",
        description: "",
      },
      allBranches: [],
      allCentres: [],
      allGroups: [],
      allGroupRoleNames: [],
      grNameDict: null,
      allCustomers: [],
      groupClientTypes: [],
      json_fields: {
        "Group Name ": "group_name",
        Id: "id",
        Branch: "branch",
        "Loan Balance": "balance",
        "Last Modified": "last_mod",
      },
      form: {
        assignedBranchKey: "",
        assignedCentreKey: "",
        assignedUserKey: "",
        clientRoleKey: "",
        groupEmailAddress: "",
        groupName: "",
        groupPhoneNumber: "",
        uniqueGroupId: "",
        loanCycle: 0,
        groupMobileNumber: "",
        groupNotes: "",
        createdBy: null,
        groupClientRoles: [
          {
            groupRoleKey: "string",
            clientKey: "string",
            groupRoleNameKey: "string",
          },
        ],
      },
      customFields: [],
      editForm: {
        groupKey: "",
        assignedBranchKey: "",
        assignedCentreKey: "",
        assignedUserKey: "",
        clientRoleKey: "",
        groupEmailAddress: "",
        groupName: "",
        groupPhoneNumber: "",
        uniqueGroupId: "",
        loanCycle: 0,
        groupMobileNumber: "",
        groupNotes: "",
        createdBy: null,
        groupClientRoles: [
          {
            groupRoleKey: "string",
            clientKey: "string",
            groupRoleNameKey: "string",
          },
        ],
        customFieldSetFieldsAndValue: [],
        customFieldValues: [],
      },
      groupFormErr: {
        groupKey: false,
        assignedBranchKey: false,
        assignedCentreKey: false,
        assignedUserKey: false,
        clientRoleKey: false,
        groupEmailAddress: false,
        groupName: false,
        groupPhoneNumber: false,
        uniqueGroupId: false,
        loanCycle: false,
        groupMobileNumber: false,
        groupNotes: false,
        createdBy: false,
        groupClientRoles: false,
      },
      selectedCustomerKey: null,
      groupRoleAddModel: {
        groupRoleNameKey: null,
        clientKey: null,
      },
      exportData: [],
      filterText: "",
      filterEdit: [],
      showEditedValue: 0,
    };
  },
  methods: {
    toggleFilterModal() {
      this.showFilterModal = !this.showFilterModal;
    },
    sendFilter(value) {
      const filterArray = value.map((obj) => {
        const payload = {};

        payload.filterFieldName = obj.filterName;
        payload.filterFieldOperator = obj.filterOp;

        if (obj.value1 === "" || obj.value1 === null) {
          payload.filterFieldValues = [obj.value];
          return payload;
        }
        payload.filterFieldValues = [obj.value, obj.value1];
        return payload;
      });
      this.fetchModel.filters = filterArray;
      this.fetchModel.pageIndex = 1;
      this.fetchModel.pageSize = 10;
      this.fetchModel.filterCategory = "group";
      this.getFilteredGroups();
    },
    reloadFilter() {
      this.clearPaging();
      this.deleteFilter();
      this.fetchModel.filters = [];
      this.fetchModel.filterCategory = "";
      this.fetchModel.pageIndex = 1;
      this.fetchModel.pageSize = 10;
      this.getGroups();
    },
    async getBranches() {
      try {
        this.isLoading = true;
        const res = await branchesService.get({
          branchName: null,
          branchState: "Active",
          pageIndex: 1,
          pageSize: 100,
          sortColumn: "",
          isDescending: false,
          filters: [],
        });
        this.allBranches = res.data.data.items;
        this.isLoading = false;
      } catch (e) {
        const error = e.response.data.message;
        this.alertError(error);
      }
    },
    processDownload() {
      let wb = XLSX.utils.book_new();

      wb.Props = {
        Title: "Groups List Download",
        Subject: "Groups List Excel Sheet",
        Author: "Prolean",
        createdDate: Date.now(),
      };

      wb.SheetNames.push("GroupsList");

      let sheetData = [
        ["Group Name", "Id", "Branch", "Loan Balance", "Last Modified"],
      ];

      this.exportData.forEach((item) => {
        let new_arr = [];
        new_arr.push(item.groupName);
        new_arr.push(item.uniqueGroupId);
        new_arr.push(item.branch ? item.branch.branchName : item.branchName);
        new_arr.push(0.0);
        new_arr.push(moment(item.lastModifiedDate).format("DD-MM-YYYY"));

        sheetData.push(new_arr);
      });

      let ws = XLSX.utils.aoa_to_sheet(sheetData);

      wb.Sheets["GroupsList"] = ws;

      let wbSheet = XLSX.write(wb, { bookType: "xlsx", type: "binary" });

      this.saveExcelSheet(this.convertBinaryToOctetStream(wbSheet));
    },

    convertBinaryToOctetStream(sheet) {
      let buf = new ArrayBuffer(sheet.length);
      let view = new Uint8Array(buf);

      for (let i = 0; i < sheet.length; i++) {
        view[i] = sheet.charCodeAt(i) & 0xff;
      }

      return buf;
    },

    saveExcelSheet(data) {
      const url = URL.createObjectURL(
        new Blob([data], { type: "application/vnd.ms-excel" })
      );

      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "groups.xlsx");
      document.body.appendChild(link);
      link.click();
    },

    pdfDownload() {
      const columns = [
        { title: "Group Name", dataKey: "group_name" },
        { title: "Id", dataKey: "id" },
        { title: "Branch", dataKey: "branch" },
        { title: "Loan Balance", dataKey: "balance" },
        { title: "Last Modified", dataKey: "last_mod" },
      ];

      let doc = new jsPDF("l", "pt", "a4");
      doc.setFontSize(6);
      doc.autoTable(columns, this.download);
      doc.save("groups.pdf");
    },
    async getCentres() {
      this.allCentres = [];
      try {
        this.isLoading = true;
        "l", "pt", "a4";
        const res = await centresService.get({
          centreName: null,
          centreStatus: "Active",
          pageIndex: 1,
          pageSize: 100,
          sortColumn: "",
          isDescending: false,
        });
        this.allCentres = res.data.data.items;
        this.isLoading = false;
      } catch (e) {
        const error = e.response.data.message;
        this.alertError(error);
      }
    },
    async getGroups() {
      try {
        this.clearPaging();
        this.isLoading = true;
        this.fetchModel.filters = [];
        this.fetchModel.filterCategory = "";
        const res = await groupService.getGroups(this.fetchModel);
        this.allGroups = res.data.data.items;
        this.exportData = res.data.data.items;
        this.paging = res.data.data;
        this.isLoading = false;
      } catch (e) {
        const error = e.response.data.message;
        this.alertError(error);
      }
    },
    async getFilteredGroups() {
      try {
        this.clearPaging();
        this.isLoading = true;
        let data = {
          filters: this.fetchModel.filters,
          filterCategory: this.fetchModel.filterCategory,
          pageIndex: this.fetchModel.pageIndex,
          pageSize: this.fetchModel.pageSize,
        };

        const res = await ApiService.post(`GeneralFilter/ApplyFilter`, data);
        this.allGroups = res.data.data;
        this.paging = res.data;
        this.filterText = res.data.filterText.replace(/_/g, " ");
        this.filterEdit = res.data.filters;
        this.getExportData();
        this.alertSuccess("Successfully filtered groups");
        this.isLoading = false;
      } catch (e) {
        const error = e.response.data.message;
        this.alertError(error);
      }
    },
    getExportData() {
      if (this.paging.totalCount > 0) {
        let data = {
          filters: this.fetchModel.filters,
          filterCategory: this.fetchModel.filterCategory,
          pageIndex: this.fetchModel.pageIndex,
          pageSize: this.paging.totalCount,
        };
        ApiService.post(`GeneralFilter/ApplyFilter`, data)
          .then((res) => {
            this.exportData = res.data.data;
          })
          .catch((e) => {
            const error = e.response.data.message;
            this.alertError(error);
          });
      }
    },
    deleteFilter() {
      this.filterText = "";
      this.filterEdit = [];
    },
    editFilter() {
      this.showEditedValue = Math.random();
      this.toggleFilterModal();
    },
    async getCustomers() {
      try {
        this.isLoading = true;
        const res = await customerService.getCustomers({
          customerTypeKey: null,
          pageIndex: 1,
          pageSize: 1000,
          filters: [],
        });
        this.allCustomers = res.data.data.items;
        this.isLoading = false;
      } catch (e) {
        const error = e.response.data.message;
        this.alertError(error);
      }
    },
    async getCustomerTypes() {
      try {
        this.isLoading = true;
        const res = await customerTypeService.get({
          filter: {
            mode: "Or",
            filterLines: [],
          },
          pageIndex: 1,
          pageSize: 1000,
          clientType: "Group",
        });
        this.groupClientTypes = res.data.data.items;
        this.isLoading = false;
      } catch (e) {
        const error = e.response.data.message;
        this.alertError(error);
      }
    },
    async getGroupRoleNames() {
      try {
        this.isLoading = true;
        const res = await groupService.getGroupRoleNames();
        this.allGroupRoleNames = res.data.data.items;
        let dictionary = Object.assign(
          {},
          ...this.allGroupRoleNames.map((x) => ({
            [x.groupRoleNameKey]: x.groupRoleName,
          }))
        );
        this.grNameDict = dictionary;
        this.isLoading = false;
      } catch (e) {
        const error = e?.response?.data?.message;
        if (error) this.alertError(error);
      }
    },
    async getCustomFormFields(type) {
      try {
        this.isLoading = true;
        const model = {
          customFieldSetType: type,
        };
        const res = await fieldSetService.getFormFieldsByType(model);
        this.customFields = res.data.data.items;
        this.isLoading = false;
      } catch (e) {
        const error = e.response.data.message;
        this.alertError(error);
      }
    },
    editGroup(item) {
      try {
        this.getCustomFormFields("Groups");
        this.groupRoleAddModel = {
          groupRoleNameKey: null,
          clientKey: null,
        };
        this.isLoading = true;
        this.clearForm();
        const wgroup = groupService.getGroupByKey(item.groupKey);
        wgroup.then((res) => {
          const group = res.data.data;

          group.memberKeySet = new Set();
          group.memberKeyArray = [];
          group.memberNames = {};
          group.memberRoles = [];

          group.groupMembersRoles.forEach((e) => {
            group.memberKeySet.add(e.clientKey);
            // set name:  key => name
            group.memberNames[e.clientKey] = e.customerName;

            if (e.roles != null) {
              e.roles.forEach((role) => {
                group.memberRoles.push({
                  groupRoleNameKey: role.groupRoleNameKey,
                  groupRoleName: role.roleName,
                  clientKey: e.clientKey,
                  customerName: e.customerName,
                });
              });
            }
          });
          group.memberKeyArray = Array.from(group.memberKeySet);
          this.group = group;
          this.editForm = {
            groupKey: item.groupKey,
            assignedBranchKey: item.assignedBranchKey,
            assignedCentreKey: item.assignedCentreKey,
            assignedUserKey: item.assignedUserKey,
            clientRoleKey: item.clientRoleKey,
            groupEmailAddress: item.groupEmailAddress,
            groupName: item.groupName,
            groupPhoneNumber: item.groupPhoneNumber,
            uniqueGroupId: item.uniqueGroupId,
            loanCycle: item.loanCycle,
            groupMobileNumber: item.groupMobileNumber,
            groupNotes: item.groupNotes,
            createdBy: item.createdBy,
            groupClientRoles: item.groupClientRoles,
            customFieldSetFieldsAndValue: group.customFieldSetFieldsAndValue,
          };
          this.isLoading = false;
          const customFieldValues = this.editForm.customFieldSetFieldsAndValue;
          customFieldValues.forEach((data) => {
            this.customFields.forEach((fields) => {
              if (fields.fieldSetKey === data.customFieldKey) {
                fields.customFields.forEach((res) => {
                  data.fields.forEach((result) => {
                    if (res.customFieldKey === result.fieldKey) {
                      document.getElementById(result.fieldKey).value =
                        result.value;
                    }
                  });
                });
              }
            });
          });
        });
      } catch (e) {
        this.isLoading = false;
        const error = e.response.data.message;
        this.alertError(error);
      }
    },
    saveEditForm: function (e) {
      e.preventDefault();
      if (this.validategroupForm()) {
        try {
          this.isSaving = true;
          // this.editForm.groupClientRoles = this.group.memberRoles;
          this.editForm.members = [];
          this.editForm.customFieldValues = [];
          if (this.customFields.length > 0) {
            this.customFields.forEach((formSet) => {
              formSet.customFields.forEach((form) => {
                let key = form.customFieldKey;
                let value = document.getElementById(key).value;
                const formValObj = {
                  customFieldKey: key,
                  value,
                };
                this.editForm.customFieldValues.push(formValObj);
              });
            });
          }
          const memberRoleDict = {};
          this.group.memberRoles.forEach((row) => {
            if (!memberRoleDict.hasOwnProperty(row.clientKey)) {
              memberRoleDict[row.clientKey] = [];
            }
            if (row.groupRoleNameKey != null) {
              memberRoleDict[row.clientKey].push(row.groupRoleNameKey);
            }
          });
          for (const clientKey in memberRoleDict) {
            this.editForm.members.push({
              clientKey: clientKey,
              roleNameKeys: memberRoleDict[clientKey],
            });
          }

          groupService.updateGroup(this.editForm.groupKey, this.editForm).then(
            (res) => {
              this.getGroups();
              this.clearForm();
              document.getElementById("editCloseButton").click();
              this.alertSuccess("Group updated.");
              this.isSaving = false;
              this.group = res;
              this.group = null;
            },
            (err) => {
              this.isSaving = false;
              const error = err.response.data.data || err.response.data.message;
              this.alertError(error);
            }
          );
        } catch (err) {
          this.isSaving = false;
          const error = err.response.data.data || err.response.data.message;
          this.alertError(error);
        }
      } else {
        this.alertWarning("Some form fields are required.");
      }
    },
    addMemberToGroup(customerKey) {
      if (!this.group.memberKeySet.has(customerKey)) {
        const c = this.allCustomers.filter((x) => x.customerKey == customerKey);
        if (c.length > 0) {
          const customer = c[0];
          let name = `${customer.customerFirstName} ${customer.customerLastName}`;

          this.group.memberKeySet.add(customerKey);
          this.group.memberNames[customerKey] = name;
          this.group.memberRoles.push({
            groupRoleNameKey: null,
            groupRoleName: null,
            clientKey: customerKey,
            customerName: name,
          });
          this.group.memberKeyArray = Array.from(this.group.memberKeySet);
        }
      }
    },
    deleteMemberFromGroup(item) {
      this.group.memberKeySet.delete(item);
      delete this.group.memberNames[item];
      this.group.memberRoles = this.group.memberRoles.filter(
        (x) => x.clientKey != item
      );
      this.group.memberKeyArray = Array.from(this.group.memberKeySet);
    },
    addGroupRole() {
      if (
        this.groupRoleAddModel.groupRoleNameKey != null &&
        this.groupRoleAddModel.clientKey != null
      ) {
        if (!(this.groupRoleAddModel.clientKey in this.group.memberNames)) {
          this.groupRoleAddModel = {
            groupRoleNameKey: null,
            clientKey: null,
          };
          return;
        }
        let exists = this.group.memberRoles.filter(
          (x) =>
            x.clientKey == this.groupRoleAddModel.clientKey &&
            x.groupRoleNameKey == this.groupRoleAddModel.groupRoleNameKey
        );
        if (exists.length > 0) {
          this.groupRoleAddModel = {
            groupRoleNameKey: null,
            clientKey: null,
          };
          return;
        }

        let cname = this.group.memberNames[this.groupRoleAddModel.clientKey];
        this.group.memberRoles.push({
          groupRoleNameKey: this.groupRoleAddModel.groupRoleNameKey,
          groupRoleName:
            this.grNameDict[this.groupRoleAddModel.groupRoleNameKey],
          clientKey: this.groupRoleAddModel.clientKey,
          customerName: cname,
        });
        this.groupRoleAddModel = {
          groupRoleNameKey: null,
          clientKey: null,
        };
      }
    },
    removeGroupRole(clientKey, groupRoleNameKey) {
      this.group.memberRoles = this.group.memberRoles.filter(
        (x) =>
          x.customerKey != clientKey && x.groupRoleNameKey != groupRoleNameKey
      );
    },
    clearForm() {
      this.group = null;
      this.form = {
        assignedBranchKey: "",
        assignedCentreKey: "",
        assignedUserKey: "",
        clientRoleKey: "",
        groupEmailAddress: "",
        groupName: "",
        groupPhoneNumber: "",
        uniqueGroupId: "",
        loanCycle: 0,
        groupMobileNumber: "",
        groupNotes: "",
        createdBy: null,
        groupClientRoles: [
          {
            groupRoleKey: "string",
            clientKey: "string",
            groupRoleNameKey: "string",
          },
        ],
      };
      this.editForm = {
        groupKey: "",
        assignedBranchKey: "",
        assignedCentreKey: "",
        assignedUserKey: "",
        clientRoleKey: "",
        groupEmailAddress: "",
        groupName: "",
        groupPhoneNumber: "",
        uniqueGroupId: "",
        loanCycle: 0,
        groupMobileNumber: "",
        groupNotes: "",
        createdBy: null,
        groupClientRoles: [
          {
            groupRoleKey: "string",
            clientKey: "string",
            groupRoleNameKey: "string",
          },
        ],
      };
      this.groupRoleAddModel = {
        groupRoleNameKey: null,
        clientKey: null,
      };
    },
    changePage(pageNum) {
      this.fetchModel.pageIndex = pageNum;
      this.getGroups();
    },
    changePageSize(pageNum) {
      this.fetchModel.pageSize = pageNum;
      if (this.fetchModel.filterCategory === "group") {
        this.getFilteredGroups();
      } else {
        this.getGroups();
      }
    },
    stepPage(direction) {
      if (direction == "next") {
        this.fetchModel.pageIndex = this.fetchModel.pageIndex + 1;
      }
      if (direction == "previous") {
        this.fetchModel.pageIndex = this.fetchModel.pageIndex - 1;
      }
      if (this.fetchModel.filterCategory === "group") {
        this.getFilteredGroups();
      } else {
        this.getGroups();
      }
    },
    clearPaging() {
      this.paging = {
        pageIndex: 0,
        totalPages: 0,
        pageSize: 0,
        totalCount: 0,
        hasPreviousPage: false,
        hasNextPage: false,
      };
    },
    validategroupForm() {
      this.cleargroupFormValidation();
      if (
        this.editForm.groupName &&
        this.editForm.uniqueGroupId &&
        this.editForm.groupEmailAddress &&
        this.editForm.clientRoleKey &&
        this.editForm.assignedBranchKey &&
        this.editForm.assignedCentreKey &&
        this.editForm.assignedUserKey
      ) {
        return true;
      }

      if (!this.editForm.groupName) {
        this.groupFormErr.groupName = true;
      }
      if (!this.editForm.uniqueGroupId) {
        this.groupFormErr.uniqueGroupId = true;
      }
      if (!this.editForm.groupEmailAddress) {
        this.groupFormErr.groupEmailAddress = true;
      }
      if (!this.editForm.clientRoleKey) {
        this.groupFormErr.clientRoleKey = true;
      }
      if (!this.editForm.assignedBranchKey) {
        this.groupFormErr.assignedBranchKey = true;
      }
      if (!this.editForm.assignedCentreKey) {
        this.groupFormErr.assignedCentreKey = true;
      }
      if (!this.editForm.assignedUserKey) {
        this.groupFormErr.assignedUserKey = true;
      }

      return false;
    },
    cleargroupFormValidation() {
      this.groupFormErr = {
        groupKey: false,
        assignedBranchKey: false,
        assignedCentreKey: false,
        assignedUserKey: false,
        clientRoleKey: false,
        groupEmailAddress: false,
        groupName: false,
        groupPhoneNumber: false,
        uniqueGroupId: false,
        loanCycle: false,
        groupMobileNumber: false,
        groupNotes: false,
        createdBy: false,
        groupClientRoles: false,
      };
    },
    alertSuccess(description) {
      this.alert.status = "success";
      this.alert.title = "Success";
      this.alert.description = description;
      this.alert.show = true;
      const self = this;
      setTimeout(function () {
        self.alert.show = false;
      }, 15000);
    },
    alertWarning(description) {
      this.alert.status = "warn";
      this.alert.title = "Warning";
      this.alert.description = description;
      this.alert.show = true;
      const self = this;
      setTimeout(function () {
        self.alert.show = false;
      }, 15000);
    },
    alertError(description) {
      this.alert.status = "fail";
      this.alert.title = "Error";
      this.alert.description = description;
      this.alert.show = true;
      const self = this;
      setTimeout(function () {
        self.alert.show = false;
      }, 15000);
    },
  },
  async created() {
    this.getGroups();
    this.getCentres();
    this.getBranches();
    this.getGroupRoleNames();
    this.getCustomers();
    this.getCustomerTypes();
  },
  filters: {
    formatDatePipe: function (date) {
      return date != null ? moment(date).format("Do-MMM-YYYY") : "";
    },
  },
  components: {
    draggable,
    // downloadExcel: JsonExcel,
    CreateFilter: () =>
      import(
        /* WebpackChunkName: "CreateFilter" */ "@/view/pages/deposit/filter.vue"
      ),
  },
  computed: {
    ...mapState({
      user: (state) => state.auth.user,
    }),

    userCanEditGroup() {
      const group = this.user.scopes.find(
        (item) => item.scopeName === Role.Group
      );
      const { permissionsList } = group;
      return permissionsList.CanEditGroup;
    },

    userCanExportToExcel() {
      const user = this.user.scopes.find(
        (item) => item.scopeName === Role.Documents
      );
      const { permissionsList } = user;
      return permissionsList.CanExportToExcel;
    },

    userCanExportToPDF() {
      const user = this.user.scopes.find(
        (item) => item.scopeName === Role.Documents
      );
      const { permissionsList } = user;
      return permissionsList.CanDownloadDocument;
    },

    download() {
      let data = [];
      this.exportData.forEach((item) => {
        let new_obj = {};
        new_obj.group_name = item.groupName;
        new_obj.id = item.uniqueGroupId;
        new_obj.branch = item.branch ? item.branch.branchName : item.branchName;
        new_obj.balance = 0.0;
        new_obj.last_mod = moment(item.lastModifiedDate).format("DD-MM-YYYY");

        data.push(new_obj);
      });

      return data;
    },

    isLargeSizePaged() {
      return this.paging?.totalPages > 10;
    },
  },
});
</script>
<style scoped>
.move {
  cursor: move;
}
</style>
