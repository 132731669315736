import ApiService from "@/core/services/general.service";
import JwtService from "@/core/services/jwt.service";

// Endpoints
const resource = process.env.VUE_APP_API_BASE_GENERAL_URL + "Customer";
const customerService = {
  getCustomers(model) {
    // return ApiService.get(resource, "");
    if (JwtService.getToken()) {
      ApiService.setHeader();
      return ApiService.post(`${resource}/customers`, model);
    }
  },

  getCustomerByKey(key) {
    // return ApiService.get(resource, "");
    if (JwtService.getToken()) {
      ApiService.setHeader();
      return ApiService.get(resource, key);
    }
  },

  createCustomer(data) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      return ApiService.post(resource, data);
    }
  },

  updateCustomer(key, data) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      return ApiService.update(resource, key, data);
    }
  },

  deleteCustomer(id) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      return ApiService.delete(`${resource}/${id}`, id);
    }
  },

  updateStatus(data) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      return ApiService.update(`${resource}`, "status", data);
    }
  },
};

export default customerService;
