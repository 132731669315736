import ApiService from "@/core/services/general.service";
import JwtService from "@/core/services/jwt.service";

// Endpoints
const resource = process.env.VUE_APP_API_BASE_GENERAL_URL + "CustomerType";
const customerTypeService = {
  get(model) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      return ApiService.post(`${resource}/customertypes?`, model);
    }
  },
  getByKey(key) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      return ApiService.get(resource, key);
    }
  },

  create(data) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      return ApiService.post(resource, data);
    }
  },

  update(key, data) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      return ApiService.update(resource, key, data);
    }
  },

  delete(key) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      return ApiService.delete(`${resource}/${key}`, key);
    }
  },
};

export default customerTypeService;
