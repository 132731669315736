import ApiService from "@/core/services/general.service";
import JwtService from "@/core/services/jwt.service";

// Endpoints
const resource = process.env.VUE_APP_API_BASE_GENERAL_URL + "CustomFields";
const fieldSetService = {
  getFieldSets(model) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      return ApiService.post(`${resource}/fieldSets`, model);
    }
  },
  addFieldSet(data) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      return ApiService.post(`${resource}/fieldSet`, data);
    }
  },
  updateFieldSet(data) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      return ApiService.put(`${resource}/fieldSet`, data);
    }
  },
  deleteFieldSet(key) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      return ApiService.delete(`${resource}/fieldSet/${key}`, key);
    }
  },
  getAllFields(model) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      return ApiService.post(`${resource}/fields/`, model);
    }
  },
  getFormFieldsByType(model) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      return ApiService.post(`${resource}/FieldSets/FieldSetType`, model);
    }
  },
  getFields(model) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      return ApiService.post(`${resource}/fields/fieldsets`, model);
    }
  },
  getField(key) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      return ApiService.get(`${resource}/fields/${key}`);
    }
  },
  addField(data) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      return ApiService.post(`${resource}/field`, data);
    }
  },
  updateField(data) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      return ApiService.put(`${resource}/field`, data);
    }
  },
  deleteField(key) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      return ApiService.delete(`${resource}/field/${key}`, key);
    }
  },
};

export default fieldSetService;
